import { defineComponent, onMounted, onBeforeUnmount, ref } from "vue";
import bus from "@/utils/bus";
import videojs from "video.js";
import "video.js/dist/video-js.css";
export default defineComponent({
  props: {
    poster: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    mute: {
      type: Boolean,
      default: true
    },
    play: {
      type: Boolean,
      default: false
    },
    refName: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  setup(props, {
    emit
  }) {
    const player = ref(null);
    let videoPlayer = ref(null);
    const loading = ref(true);
    const videoOptions = {
      autoplay: props.play,
      // autoplay: "autoplay",
      controls: false,
      loop: true,
      muted: props.mute,
      aspectRatio: "16:9",
      poster: props.poster,
      fullscreen: {
        options: {
          navigationUI: "hide"
        }
      },
      sources: [{
        src: props.url,
        type: "video/mp4"
      }]
    };
    const currentTime = ref(0);
    const totalTime = ref(0);
    let formattedTotalTime = ref();
    let formattedCurrentTime = ref();
    let playAndStop = ref("播放");
    const progressWidth = ref("0%");
    onMounted(() => {
      player.value = videojs(`videoPlayerId${props.index}`, videoOptions, function () {
        const _this = this;

        _this.on("error", () => {
          _this.errorDisplay.close();
        });

        _this.on("canplaythrough", () => {
          var _player$value;

          loading.value = false;
          totalTime.value = (_player$value = player.value) === null || _player$value === void 0 ? void 0 : _player$value.duration();
        });

        _this.on("timeupdate", () => {
          currentTime.value = player.value.currentTime();
          const totalSeconds = Math.floor(totalTime.value);
          const currentSeconds = Math.floor(currentTime.value);
          formattedTotalTime.value = formatTime(totalSeconds);
          formattedCurrentTime.value = formatTime(currentSeconds);
          progressWidth.value = `${currentTime.value / totalTime.value * 100}%`;
        });
      });
    });

    function formatTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor(seconds % 3600 / 60);
      const remainingSeconds = seconds % 60;
      return `${hours}:${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
    }

    onBeforeUnmount(() => {
      if (player.value) {
        player.value.dispose();
      }
    }); // 播放

    const getPlay = () => {
      if (player.value && playAndStop.value == "播放") {
        player.value.play();
        playAndStop.value = "暂停";
      } else {
        player.value.pause();
        playAndStop.value = "播放";
      }
    };

    function handleMouseEnter() {
      var _player$value2;

      if (player.value) {
        player.value.play();
        playAndStop.value = "暂停";
      }

      (_player$value2 = player.value) === null || _player$value2 === void 0 ? void 0 : _player$value2.duration(); // emit("videoTime", player.value?.duration());
    }

    function handleMouseLeave() {
      if (player.value) {
        player.value.pause();
        playAndStop.value = "播放";
      }
    }

    function mouseMove(event) {
      var _player$value3, _player$value4;

      const videoleft = videoPlayer.value.getBoundingClientRect().left;
      const clientx = event.clientX;
      const videoWidth = videoPlayer.value.clientWidth;
      const ratio = (clientx - videoleft) / videoWidth;
      const videoduration = ((_player$value3 = player.value) === null || _player$value3 === void 0 ? void 0 : _player$value3.duration()) || 0;
      (_player$value4 = player.value) === null || _player$value4 === void 0 ? void 0 : _player$value4.currentTime(videoduration * ratio);
      emit("videoratio", ratio);
    }

    function jumpToTime(event) {
      var _player$value5, _player$value6;

      const progressBar = document.querySelector(".progress-bar");
      const progressBarWidth = progressBar.clientWidth;
      const clickedPosition = event.clientX - progressBar.getBoundingClientRect().left;
      const ratio = clickedPosition / progressBarWidth;
      const videoduration = ((_player$value5 = player.value) === null || _player$value5 === void 0 ? void 0 : _player$value5.duration()) || 0;
      console.log(videoduration * ratio);
      (_player$value6 = player.value) === null || _player$value6 === void 0 ? void 0 : _player$value6.currentTime(videoduration * ratio);
    } // 点击弹出弹框


    const getVideoDialog = url => {
      bus.emit("clickVideo", url);
    };

    return {
      player,
      loading,
      handleMouseEnter,
      handleMouseLeave,
      mouseMove,
      videoPlayer,
      getPlay,
      currentTime,
      totalTime,
      progressWidth,
      jumpToTime,
      formattedTotalTime,
      formattedCurrentTime,
      playAndStop,
      getVideoDialog
    };
  }

});