// import { broadcast } from "vuex-iframe-sync";
const statistic = {
  state: () => ({
    statisticName:
      JSON.parse(JSON.stringify(sessionStorage.getItem("statisticName"))) ||
      null,
  }),
  mutations: {
    SET_STATISTIC_NAME: (state: any, name: string) => {
      state.statisticName = name;
      sessionStorage.statisticName = name;
    },
  },
  getters: {},
  actions: {},
};

export default statistic;
