import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  createWebHashHistory,
} from "vue-router";
const routes: Array<RouteRecordRaw> = [
  // 登录页
  {
    path: "/",
    redirect: "/home",
    component: () => import("@/views/mainPage.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        meta: { title: "首页", keepAlive: true },
        component: () => import("@/views/home/index.vue"),
      },
      {
        path: "/home2",
        name: "home2",
        meta: { title: "3D首页" },
        component: () => import("@/views/home/index3D.vue"),
      },
      {
        path: "/product",
        name: "product",
        meta: { title: "产品" },
        component: () => import("@/views/product/index.vue"),
      },
      {
        path: "/model-square",
        name: "model-square",
        meta: { title: "模型广场" },
        component: () => import("@/views/model-square/index.vue"),
      },
      {
        path: "/project",
        name: "project",
        meta: { title: "项目案例" },
        component: () => import("@/views/project/index.vue"),
      },
      {
        path: "/activity",
        name: "activity",
        meta: { title: "最新活动" },
        component: () => import("@/views/activity/index.vue"),
      },
      {
        path: "/industry-trends",
        name: "industry-trends",
        meta: { title: "行业动态" },
        component: () => import("@/views/industry-trends/index.vue"),
      },
    ],
  },
];

// const router = createRouter({
//   // history:  createWebHistory(process.env.BASE_URL),
//   history: createWebHashHistory(),
//   routes,
// });

// export default router;

export { routes };
