import { defineComponent, onMounted, onBeforeUnmount, ref } from "vue";
import videojs from "video.js";
import "video.js/dist/video-js.css";
export default defineComponent({
  props: {
    poster: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    mute: {
      type: Boolean,
      default: true
    },
    play: {
      type: Boolean,
      default: false
    },
    refName: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  setup(props, {
    emit
  }) {
    const player = ref(null);
    let videoPlayer = ref(null);
    const loading = ref(true);
    const videoOptions = {
      autoplay: props.play,
      controls: false,
      loop: true,
      muted: props.mute,
      aspectRatio: "16:9",
      poster: props.poster,
      fullscreen: {
        options: {
          navigationUI: "hide"
        }
      },
      sources: [{
        src: props.url,
        type: "video/mp4"
      }]
    };
    onMounted(() => {
      player.value = videojs(`videoPlayerId${props.index}`, videoOptions, () => {
        let _this = this;

        _this.on("error", () => {
          _this.errorDisplay.close();
        });

        _this.on("canplaythrough", () => {
          loading.value = false;
        });
      });
    });
    onBeforeUnmount(() => {
      if (player.value) {
        player.value.dispose();
      }
    }); // 播放

    const getPlay = () => {
      if (player.value) {
        player.value.play();
      }
    };

    function handleMouseEnter() {
      var _player$value;

      if (player.value) {
        player.value.play();
      }

      (_player$value = player.value) === null || _player$value === void 0 ? void 0 : _player$value.duration(); // emit("videoTime", player.value?.duration());
    }

    function handleMouseLeave() {
      if (player.value) {
        player.value.pause();
      }
    }

    function mouseMove(event) {
      var _player$value2, _player$value3;

      console.log(event.clientX);
      const videoleft = videoPlayer.value.getBoundingClientRect().left;
      const clientx = event.clientX;
      const videoWidth = videoPlayer.value.clientWidth;
      const ratio = (clientx - videoleft) / videoWidth;
      const videoduration = ((_player$value2 = player.value) === null || _player$value2 === void 0 ? void 0 : _player$value2.duration()) || 0;
      (_player$value3 = player.value) === null || _player$value3 === void 0 ? void 0 : _player$value3.currentTime(videoduration * ratio);
      emit("videoratio", ratio);
    }

    return {
      player,
      loading,
      handleMouseEnter,
      handleMouseLeave,
      mouseMove,
      videoPlayer,
      getPlay
    };
  }

});