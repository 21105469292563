import { defineComponent, ref } from "vue";
import VideoPlayer from "./VideoPlayer.vue";
export default defineComponent({
  components: {
    VideoPlayer
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  setup(props) {
    const videoplayerprogress = ref(0);
    const videoPlayerRef = ref(undefined);

    function videoratio(value) {
      console.log(value);
      videoplayerprogress.value = Math.round(24 * value);
    }

    return {
      videoplayerprogress,
      videoratio,
      videoPlayerRef
    };
  }

});