// 引入element
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

import locale from "element-plus/lib/locale/lang/zh-cn";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

import YddModule from "ydd-module";
import "ydd-module/ydd-module.css";

import actions from "./qiankun/actions";

import { createApp } from "vue";
import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import App from "./App.vue";
import { routes } from "./router";
import store from "./store";

// import { routers } from "@/utils/localData";

import directive from "@/directive"; // 引入权限方法
import { useDict } from "@/utils/dict"; //
// 引入全局弹框方法
import plugins from "./plugins";
// 引入全局的下拉筛选
import { selectListPubic } from "@/plugins/public";

import "./public-path";
const APP_NAME = require("../package.json").name;

const loadView = (view: any) => {
  return () => import(`@/views/${view}.vue`);
};

const getTree = (TreeArr2: any) => {
  TreeArr2.forEach((item: any) => {
    (item.path = item.path.replace("/business", "")),
      (item.name = item.name),
      (item.redirect = item.redirect ? item.redirect : "");

    item.component = loadView(item.component);
    if (item.children && item.children != null && item.children != undefined) {
      getTree(item.children);
    }
  });
};

// 不能直接挂载 需要切换的时候 调用mount方法时候再去挂载
let history = <any>null;
let router = <any>null;
let app = <any>null;
function render(props: any) {
  app = <any>createApp(App);
  const { container } = props;
  (history = createWebHashHistory(container ? `/${APP_NAME}` : "/")),
    (router = createRouter({
      history,
      routes,
      scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
          return savedPosition;
        } else {
          return { top: 0 };
        }
      },
    }));
  // 过滤非本项目路由
  // let routerList = JSON.parse(JSON.stringify(props.routes.qianKunRouterData));
  // routerList = routerList.filter((item: any) => {
  //   if (item.name.indexOf("/business/") != -1) {
  //     return item;
  //   }
  // });
  // // 递归注册动态路由
  // getTree(routerList);
  // routerList.forEach((item: any) => {
  //   router.addRoute(item);
  // });

  // router.options.routes = routerList;
  // // 全局方法挂载
  // app.config.globalProperties.useDict = useDict;
  // app.config.globalProperties.$modal = plugins.modal; //全局消息弹框
  // app.config.globalProperties.selectListPubic = selectListPubic;
  // app.config.globalProperties.download = props.download;

  app
    .use(store)
    .use(router)
    // .use(ElementPlus, { locale })
    .use({ locale })
    .use(YddModule)
    .use(directive) // 权限注册
    .mount(container ? container.querySelector("#app") : "#app");

  const requireComponent = require.context("@/components", true, /\.vue$/);
  requireComponent.keys().forEach((ele) => {
    const moduleObj = requireComponent(ele).default;
    app.component(moduleObj.name, moduleObj);
  });
  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
  }
}
// 独立运行时
if (!(window as any).__POWERED_BY_QIANKUN__) {
  console.log("独立运行");
  render({});
}

/**
 * bootstrap ： 在微应用初始化的时候调用一次，之后的生命周期里不再调用
 */
export async function bootstrap() {
  console.log("bootstrap");
}

/**
 * mount ： 在应用每次进入时调用
 */
export async function mount(props: any) {
  console.log("乾坤运行");
  actions.setActions(props);
  render(props);
}

/**
 * unmount ：应用每次 切出/卸载 均会调用
 */
export async function unmount() {
  console.log("chi unmount");
  router = null;
  app = null;
  history = null;
  // window.location.reload(); // 离开的时候刷新一下界面 第一版有刷新问题不建议
}
