import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: "MyEcharts",
    ref: "MyEcharts",
    style: _normalizeStyle({
      width: _ctx.wd,
      height: _ctx.hd
    })
  }, null, 4)]);
}