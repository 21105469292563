// import { broadcast } from "vuex-iframe-sync";
const userInfo = {
  state: () => ({
    token: JSON.parse(JSON.stringify(sessionStorage.getItem("token"))) || null,
    userId:
      JSON.parse(JSON.stringify(sessionStorage.getItem("userId"))) || null,

    userRole:
      JSON.parse(JSON.stringify(sessionStorage.getItem("userRole"))) || null,
    orgRole:
      JSON.parse(JSON.stringify(sessionStorage.getItem("orgRole"))) || null,
  }),
  mutations: {
    SET_TOKEN: (state: any, token: string) => {
      state.token = token;
      sessionStorage.token = token;
    },

    SET_USER_ID: (state: any, userId: any) => {
      state.userId = userId;
      sessionStorage.userId = userId;
    },

    DEL_TOKEN(state: any) {
      sessionStorage.removeItem("token");
      sessionStorage.clear();
    },

    SET_USER_ROLE: (state: any, userRole: any) => {
      state.userRole = userRole;
      sessionStorage.userRole = userRole;
    },

    SET_ORG_ROLE: (state: any, orgRole: any) => {
      state.orgRole = orgRole;
      sessionStorage.orgRole = orgRole;
    },
  },
  getters: {},
  actions: {},
};

export default userInfo;
