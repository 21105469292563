import "core-js/modules/es.array.push.js";
import { defineComponent, ref, getCurrentInstance } from "vue";
import { ElUpload, ElButton } from "element-plus";
export default defineComponent({
  components: {
    ElUpload,
    ElButton
  },
  props: {
    uploadUrl: {
      type: String,
      required: true
    },
    limit: {
      type: Number,
      required: true,
      default: 1
    }
  },

  setup(props, {
    emit
  }) {
    const {
      proxy
    } = getCurrentInstance();
    const fileList = ref([]);

    const handleChange = file => {
      const isZip = file.name.split(".").pop();
      const isValidSize = file.size / 1024 / 1024 < 500; // 限制10MB以内

      const isValidCount = fileList.value.length < props.limit;

      if (isZip !== "zip") {
        proxy.$message.error("只能上传zip格式的文件");
      }

      if (!isValidSize) {
        proxy.$message.error("文件大小不能超过10MB");
      }

      if (!isValidCount) {
        proxy.$message.error(`最多只能上传${props.limit}个文件`);
      }

      if (isZip !== "zip" || !isValidSize || !isValidCount) {
        fileList.value = JSON.parse(JSON.stringify(fileList.value));
        return false;
      }

      fileList.value.push(file);
      emit("backFile", file);
      return isZip && isValidSize && isValidCount;
    };

    const handleRemove = (file, uploadFiles) => {
      console.log(uploadFiles);
      fileList.value = uploadFiles;
      emit("backFile", "");
    };

    const handleExceed = () => {
      proxy.$message.warning(`最多只能上传${props.limit}个文件`);
    };

    return {
      fileList,
      handleChange,
      handleExceed,
      handleRemove
    };
  }

});