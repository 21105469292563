import * as echarts from "echarts";
import { defineComponent, reactive, onBeforeMount, onMounted, ref, watch, markRaw, onUnmounted } from "vue";
export default defineComponent({
  name: "",
  props: {
    echartsData: {
      type: Object
    },
    wd: {
      type: String,
      default: "100%"
    },
    hd: {
      type: String,
      default: "200px"
    },
    watchShow: {
      type: Boolean,
      default: false
    }
  },

  setup(props) {
    const MyEcharts = ref();
    const state = reactive({
      myChart: null
    });
    onBeforeMount(() => {//2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
      getMyEcharts(MyEcharts === null || MyEcharts === void 0 ? void 0 : MyEcharts._value, props.echartsData);
      window.addEventListener("resize", getresize);
    });
    onUnmounted(() => {
      window.removeEventListener("resize", getresize);
    });
    watch(props, newValue => {
      if (!props.watchShow) {
        getMyEcharts(MyEcharts._value, newValue.echartsData);
      }
    }, {
      deep: true
    });

    const getMyEcharts = (Dom, Data) => {
      setTimeout(() => {
        if (state.myChart) {
          state.myChart.dispose();
        }

        state.myChart = markRaw(echarts.init(Dom));
        state.myChart.setOption(Data);
        window.addEventListener("resize", function () {
          state.myChart.resize();
        });
      }, 300); // let myChart = echarts.init(Dom);
      // state.myChart = myChart;
      // myChart.setOption(Data);
      // window.addEventListener("resize", function () {
      //   myChart.resize();
      // });
    };

    const getresize = () => {
      setTimeout(() => {
        state.myChart.resize();
      }, 500);
    };

    return {
      MyEcharts,
      getMyEcharts
    };
  }

});