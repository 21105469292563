import { createStore } from "vuex";
import user from "./modules/user";
import statistic from "./modules/statistic";

const store = createStore({
  state: {
    closeList: [],
  },
  getters: {},
  mutations: {
    setCloseList(state, data) {
      state.closeList = data;
    },
    // clearUserInfo(state) {
    //   state.closeList = [];
    // },
  },

  actions: {
    // login({ commit }, closeList) {
    //   // 模拟登录成功
    //   commit("setCloseList", closeList);
    // },
    // logout({ commit }) {
    //   // 清除用户信息
    //   commit("clearCloseList");
    //   // 跳转到登录页
    //   router.push("/login");
    // },
  },
  modules: {
    user: user,
    statistic: statistic,
  },
});

export default store;
